import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import axios from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postToEndpoint } from "../AxiosHelper";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '15rem'
    },
    buttonContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

export type VerifyPageProps = {
    token: string | undefined;
    requestLogout: () => void;
}

function VerifyPage(props: VerifyPageProps): JSX.Element {
    const classes = useStyles();
    const navigate = useNavigate();
    const { token, requestLogout } = props;
    const location = useLocation();
    const userRegistration = new URLSearchParams(location.search).get('userRegistration');
    const [result, setResult] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        if (token !== undefined) {
            requestLogout();
        }
    }, [requestLogout, token]);

    useEffect(() => {
        if (userRegistration !== null && token === undefined) {
            postToEndpoint('verify', { userRegistration: userRegistration })
                .then(() => { setResult(t('user.verify.success')); })
                .catch((error) => {
                    const response = error.response;
                    let errorLabel = 'user.verify.fail.general_error';
                    if (axios.isAxiosError(error)) {
                        if (response?.status === 404) {
                            errorLabel = 'user.verify.fail.not_valid';
                        }
                    }
                    setResult(t(errorLabel));
                    setIsError(true);
                });
        } else {
            setResult(t('user.verify.fail.general_error'));
        }
    }, [token, userRegistration]);

    return <form className={classes.root}>
        <Grid container className={classes.gridContainer}>
            <Grid item>
                <Typography variant="body1" className={classes.title} style={{ width: '100%' }} color={isError ? "error" : "primary"}>
                    {result}
                </Typography>
            </Grid>
            <Grid item style={{ width: '100%' }}>
                <Button variant="contained" color="primary" onClick={() => navigate('/login')} fullWidth>
                    {t('user.verify.btn')}
                </Button>
            </Grid>
        </Grid>
    </form>
}

export default VerifyPage;
