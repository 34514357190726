import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Link, TextField, Tooltip, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { KeyboardEventHandler, useCallback, useEffect, useState } from "react";


export type AccordionWithTextFieldAndButtonProps = {
    disabled: boolean
    summaryTooltip: string
    summaryTextLeft: string
    summaryCounter?: number | undefined
    summaryCounterColor?: string | undefined
    expanded: boolean
    onExpandedChange: () => void
    onClick: (text: string) => void
    isProcessingClick: boolean
    textfieldEmptyText: string
    validateText?: ((text: string) => string) | undefined
    buttonText: string
    buttonTooltip: string
    lastErrorText: string
    clickableLink?: string | undefined
    clickableLinkText?: string | undefined
    children?: JSX.Element[] | JSX.Element
}

export const AccordionWithTextFieldAndButton = (props: AccordionWithTextFieldAndButtonProps) => {
    const {
        disabled,
        summaryTooltip,
        summaryTextLeft,
        summaryCounter,
        summaryCounterColor,
        expanded,
        onExpandedChange,
        onClick,
        isProcessingClick,
        textfieldEmptyText,
        validateText,
        buttonText,
        buttonTooltip,
        lastErrorText,
        clickableLink,
        clickableLinkText,
        children
    } = props;

    const [text, setText] = useState('');
    const [errorText, setErrorText] = useState(lastErrorText);

    useEffect(() => {
        if (validateText !== undefined) {
            setErrorText(validateText(text));
        }
    }, [text, validateText]);

    //set the error text from the props when it changes
    useEffect(() => {
        setErrorText(lastErrorText);
    }, [lastErrorText]);

    //clear the error once the text changes
    useEffect(() => {
        setErrorText('')
    }, [text]);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            props.onClick(text);
        }
    }, [text, props]);

    return <Accordion
        disabled={disabled}
        expanded={expanded}
        onChange={onExpandedChange}
        style={{ margin: 10 }}
    >
        <AccordionSummary
            title={summaryTooltip}
            expandIcon={<ExpandMore />}
        >
            <Grid container alignItems='center' justifyContent='flex-end'>
                <Grid item xs={10}>
                    <Typography style={{ fontWeight: 'bold' }}>{summaryTextLeft}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Typography style={{
                        color: summaryCounterColor,
                        fontWeight: 'bold'
                    }}>
                        {summaryCounter}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container alignItems='center' justifyContent='center' spacing={2} direction="column">
                {children}
                <Grid item style={{ width: '100%' }}>
                    <TextField
                        label={textfieldEmptyText}
                        variant="outlined"
                        value={text}
                        error={errorText !== ''}
                        helperText={errorText}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setText(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <Tooltip title={text === '' ? buttonTooltip : ''}>
                        <span>
                            <Button variant="contained"
                                disabled={errorText !== '' || isProcessingClick}
                                color="primary"
                                onClick={() => onClick(text)}
                                fullWidth
                            >
                                {isProcessingClick ? <CircularProgress /> : buttonText}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <Typography variant='caption'>
                        <Link href={clickableLink} target="_blank" rel="noopener noreferrer" >
                            {clickableLinkText}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}
