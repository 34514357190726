import { DeviceState } from "./Device";
import { MonitoringSystemDescriptor, MonitoringSystemState } from "./MonitoringSystem";

export const getLocalDateStringFromUtcDateString = (utcString: string) =>
  new Date(utcString + 'Z').toLocaleString();

export const getDeviceStatusColor = (status: string | undefined):
  string => {
  switch (status?.toLocaleLowerCase()) {
    case 'off':
      return 'red';
    case 'unknown':
      return 'yellow';
    case 'active':
      return 'green';
    default:
      return '';
  }
}

export const getDeviceStatusOrder = (status: string | undefined): number => {
  switch (status?.toLocaleLowerCase()) {
    case 'off':
      return 0;
    case 'unknown':
      return 1;
    case 'active':
      return 2;
    default:
      return 3;
  }
};

export const isStringNullUndefinedOrEmpty = (s: string | undefined | null): boolean => {
  return s === null || s === undefined || s === ''
}

export const isEmailAddressValid = (email: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);
}

export const isPasswordValid = (pw: string): boolean => {
  return pw.length >= 6;
}

export const isPhoneNumberValid = (phone: string): boolean => {
  return /^\+\d+$/.test(phone);
}

export const isNameValid = (name: string): boolean => {
  return /^[a-zA-Z0-9\sáéíóöőúüűÁÉÍÓÖŐÚÜŰ]*$/.test(name);
}

export const getDeviceStateForDeviceId = (deviceId: number, monitoringSystemState: MonitoringSystemState): DeviceState | undefined => {
  return monitoringSystemState?.deviceStates.find((deviceState) => deviceState.deviceId === deviceId);
};

export const isDeviceStateRecent = (deviceState: DeviceState): boolean => {
  const lastSeen = new Date(deviceState.lastSeen);
  return Date.now() - lastSeen.getTime() < 1000 * 60 * 60 * 24 * 3;
}

export const getRecentDeviceDescriptors = (monitoringSystemDescriptor: MonitoringSystemDescriptor, monitoringSystemState: MonitoringSystemState) => {
  return monitoringSystemDescriptor.deviceDescriptors.filter((dd) => {
    if (dd.isIgnored) {
      return false;
    }

    const state = getDeviceStateForDeviceId(dd.deviceId, monitoringSystemState);
    if (state === undefined) {
      return false;
    }

    return isDeviceStateRecent(state);
  })
}

export const getOrderedRecentDeviceDescriptors = (monitoringSystemDescriptor: MonitoringSystemDescriptor, monitoringSystemState: MonitoringSystemState) => {
  return getRecentDeviceDescriptors(monitoringSystemDescriptor, monitoringSystemState).sort((d1, d2) => {
    if (d1.name < d2.name) {
      return -1;
    }

    if (d1.name > d2.name) {
      return 1;
    }

    return 0;
  });
};

export const getOrderedOldDeviceDescriptors = (monitoringSystemDescriptor: MonitoringSystemDescriptor, monitoringSystemState: MonitoringSystemState) => {
  return monitoringSystemDescriptor.deviceDescriptors
    .filter(dd => !getRecentDeviceDescriptors(monitoringSystemDescriptor, monitoringSystemState)
      .map(rdd => rdd.deviceId)
      .includes(dd.deviceId))
    .sort((d1, d2) => {
      const d1State = getDeviceStateForDeviceId(d1.deviceId, monitoringSystemState);
      const d2State = getDeviceStateForDeviceId(d2.deviceId, monitoringSystemState);
      if (d1State === undefined && d2State !== undefined) {
        return 1;
      }

      if (d1State !== undefined && d2State === undefined) {
        return -1;
      }

      if (d1.name < d2.name) {
        return -1;
      }

      if (d1.name > d2.name) {
        return 1;
      }

      return 0;
    });
};
