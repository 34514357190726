import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  resources: {
    hu: {
      translation: {
        device: {
          serial: 'Sorszám',
          status: {
            title: 'Állapot',
            unknown: 'Nem kommunikál',
            active: 'Aktív',
            off: 'Fogott és lekapcsolt',
            undefined: 'Ismeretlen'
          },
          battery: 'Akku [%]',
          temperature: 'Hőmérséklet [°C]',
          signal_strength: 'Térerő [%]',
          latitude: 'Szélesség [°]',
          longitude: 'Hosszúság [°]',
          location: 'Helyadatok',
          googleMaps: 'Ugrás Google Térképre',
          last_seen: 'Állapotfrissítés',
          disabled_tooltip: 'Még nincs adat az eszközről',
          ignore: 'Eszköz némítása nekem. Ha lenémítom, nem kapok értesítést az eszköz állapotáról és az nem jelenik meg a térképemen. Más felhasználókat, ez a beállítás nem érint.',
          unignore: 'Eszköz némításának feloldása nekem. Kapok értesítést az eszköz állapotáról és az megjelenik a térképemen. Más felhasználókat ez a beállítás nem érint.',
          ignored_title: 'Némítva van számomra. Nem kapok értesítést az eszköz állapotáról és nem jelenik meg a térképemen.',
          ignored: 'Néma',
          not_ignored: 'Nem néma',
        },
        default_ms_name: '{{userName}} rendszere',
        own_ms_name: 'Saját rendszerem',
        default_device_name: '{{id}} eszköz',
        monitoring_system_page:
        {
          disabled_tooltip: 'Nem vagy bejelentkezve',
          devices_title: 'Eszközök',
          recent_devices_title: 'Elmúlt 3 napban aktív eszközök',
          old_devices_title: 'További eszközök',
          topup: {
            budget: 'Megfigyelési napok',
            input: 'Feltöltési kód',
            btn: 'Feltöltés',
            btn_tooltip: 'Adj meg egy feltöltési kódot!',
            no_budget_tooltip: 'A megfigyelési napok elfogytak! Feltöltésükig rendszered nem működik.',
            warn_budget_tooltip: 'A megfigyelési napok hamarosan elfogynak (~{{daysLeft}} nap múlva)! Töltsd fel őket, hogy rendszered zavartalanul működjön!',
            webshop_link: 'Kódvásárláshoz kattints ide!',
            fail: {
              bad_code: 'A feltöltési kód érvénytelen.',
              used_code: 'A feltöltési kódot már használták.',
              unknown_reason: 'A feltöltés sikertelen volt. Próbálkozz később!',
            }
          },
          add_device: {
            title: 'Eszköz hozzáadása',
            input_help: 'Hozzáadási kód',
            btn: 'Hozzáadás',
            btn_tooltip: 'Add meg a hozzáadási kódot a terméken található szalagról!',
            webshop_link: 'Eszközvásárláshoz kattints ide!',
            fail: {
              bad_code: 'A hozzáadási kód érvénytelen.',
              used_code: 'A hozzáadási kódot már használták.',
              unknown_reason: 'A hozzáadás sikertelen volt. Próbálkozz később!',
            }
          },
          send_device: {
            title: 'Eszköz átadása',
            input: 'Fogadó email címe',
            btn: 'Küldés',
            btn_tooltip: 'Add meg a fogadó email címét!',
            invalid_email: 'Helytelen email cím!',
            fail: {
              not_exist: 'A megadott email címmel nincs regisztrált felhasználó.',
              unknown_reason: 'Az átadás sikertelen volt. Próbálkozz később!',
            }
          },
          accept_device: {
            btn: '{{deviceId}} elfogadása',
            fail: {
              unknown_reason: 'Az elfogadás sikertelen volt. Próbálkozz később!',
            }
          },
          cancel_send_device: {
            btn: '{{deviceId}} visszavétele',
            fail: {
              unknown_reason: 'A visszavétel sikertelen volt. Próbálkozz később!',
            }
          },
          guests: {
            title: 'Vendégek',
            input: 'Meghívandó vendég email címe',
            btn: 'Vendég meghívása',
            tooltip: 'A vendégek látják a tulajdonos rendszerében levő eszközök állapotát és értesítéseket kapnak tőlük.',
            btn_tooltip: 'Adj meg egy email címet!',
            invalid_email: 'Helytelen email cím!',
            delete_btn_tooltip: 'Vendég törlése',
            no_guest: 'Még nincsen meghívott vendég.',
            leave: 'Rendszer elhagyása',
            fail: {
              not_exist: 'A megadott email címmel nincs regisztrált felhasználó.',
              already_added: 'A megadott email címmel már van hozzáadva vendég.',
              unknown_reason: 'A meghívás sikertelen volt. Próbálkozz később!',
              remove: 'A vendég törlése sikertelen volt. Próbálkozz később!',
              leave: 'A rendszer elhagyása sikertelen volt. Próbálkozz később!',
            }
          }
        },
        map: {
          disabled_tooltip: 'Nincs még helyadat eszközről',
          only_show_recent_text: 'Csak az elmúlt 3 napban aktív eszközök',
          only_show_recent_tooltip: 'Csak az elmúlt 3 napban aktív eszköz kerül megmutatásra a térképen, a többi rejtve marad'
        },
        user: {
          login: {
            title: 'Bejelentkezés',
            login_btn: 'Bejelentkezés',
            to_signup_btn: 'Regisztrációhoz ->',
            password: 'Jelszó',
            fail: {
              email_empty: 'Email cím hiányzik.',
              email_invalid: 'Email cím helytelen.',
              password_empty: 'Jelszó hiányzik.',
              password_invalid:
                'A jelszó legalább 6 karakter hosszú.',
              bad_credential:
                'Bejelentkezés sikertelen. Ellenőrizd a belépési adatokat!',
              unverified: 'Bejelentkezés sikertelen. A regisztráció megerősítésre vár.',
              general_issue: 'Bejelentkezés sikertelen. Próbálkozz később!',
            }
          },
          signup: {
            title: 'Regisztráció',
            to_login_btn: '<- Bejelentkezéshez',
            signup_btn: 'Regisztráció',
            password_again: 'Jelszó újra',
            agree_static: 'Elfogadom az ',
            agree_dynamic: 'adatkezelést',
            email_sent: 'Regisztráció folyamatban!<1/>Erősítsd meg a regisztrációt az emailben kapott utasítások alapján!',
            fail: {
              password_again_not_match: 'A megadott jelszavak nem egyeznek.',
              already_exists: 'Ezzel az email címmel már van regisztrált felhasználó.',
              not_agreed: 'Adatkezelési beleegyezés szükséges.',
              general_issue: 'Regisztráció sikertelen. Próbálkozz később!',
            }
          },
          pwrequest: {
            btn: 'Elfelejtett jelszó',
            done: 'A jelszót kiküldtük az email címre.',
            done_tooltip: 'A jelszóemlékeztető elküldésre került a megadott email címre.',
            fail: {
              unknown_email: 'A megadott email címmel nincsen regisztrált felhasználó.',
              general_issue: 'A jelszó lekérdezése sikertelen. Próbálkozz később!'
            }
          },
          verify: {
            btn: 'Tovább a bejelentkezéshez',
            success: 'Sikeres regisztráció!',
            fail: {
              general_error: 'A regisztráció megerősítése sikertelen. Próbálkozz később!',
              not_valid: 'A regisztráció megerősítése nem lehetséges, ismételt regisztráció szükséges.',
            }
          },
          settings: {
            title: 'Felhasználói beállítások',
            user_name: 'Felhasználónév',
            email: 'Email',
            pw: 'Új jelszó',
            pw_again: 'Új jelszó újra',
            phone: 'Telefonszám',
            phone_not_available: 'Adj meg egy érvényes telefonszámot az opció aktiválásához!',
            notif: {
              event_based: 'Napi jelentés',
              event_based_title: 'Az opció kiválasztásával minden nap érkezik jelentés minden aktív eszköz állapotáról. Aktív eszköz az, amely az elmúlt 3 napban jelezte állapotát.',
              is_email: 'Értesítés emailben',
              is_phone: 'Értesítés SMS-ben',
              language: {
                title: 'Értesítés nyelve',
                hu: 'Magyar',
                en: 'Angol'
              },
              topup: {
                budget: 'SMS-ek száma',
                no_sms_balance_tooltip: 'Az SMS-ek elfogytak! Feltöltésükig email értesítéseket kapsz.',
                warn_sms_balance_tooltip: 'Az SMS-ek hamarosan elfogynak (~{{daysLeft}} nap múlva)! Töltsd fel őket, hogy megszakítás nélkül kaphass SMS értesítéseket!',
                input: 'Feltöltési kód',
                btn: 'Feltöltés',
                btn_tooltip: 'Adj meg egy feltöltési kódot!',
                fail: {
                  bad_code: 'A feltöltési kód érvénytelen.',
                  used_code: 'A feltöltési kódot már használták.',
                  unknown_reason: 'A feltöltés sikertelen volt. Próbálkozz később!',
                }
              }
            },
            save_btn: 'Mentés',
            save_disabled_tooltip_no_change: 'Nincs elmenthető változás.',
            save_disabled_tooltip_error: 'Hibás beállítások nem menthetőek.',
            logout_btn: 'Kijelentkezés',
            update_success: 'A beállítások mentése sikeres.',
            delete: {
              btn: 'Felhasználó törlése',
              confirm_title: 'Felhasználói fiók törlésének megerősítése',
              confirm_body: 'Biztosan törölni szeretnéd felhasználói fiókodat? A törléssel minden beállítás elveszik, monitorozó rendszered a megmaradt megfigyelési napokkal együtt törlésre kerül. Kérjük add meg jelszavadat a megerősítéshez:',
              pw: 'Jelszó',
              confirm_ack_btn: 'Törlés',
              confirm_cancel_btn: 'Vissza',
              fail: {
                not_allowed: 'A megadott jelszó helytelen.',
                has_devices: 'A felhasználó nem törölhető, amíg a monitorozó rendszeréhez eszközök vannak rendelve.',
                negative_budget: 'A felhasználó nem törölhető, amíg a monitorozó rendszerének büdzséje negatív.',
                unknown_error: 'A felhasználó törlése sikertelen. Próbálkozz később!',
              },
            },
            credentials: {
              btn: 'Bejelentkezési adatok módosítása',
              confirm_title: 'Bejelentkezési adatok módosításának megerősítése',
              confirm_body: 'A bejelentkezési adatok (email cím és/vagy jelszó) módosításához add meg a jelszavadat:',
              pw: 'Jelszó',
              confirm_ack_btn: 'Mentés',
              confirm_cancel_btn: 'Vissza',
              fail: {
                not_allowed: 'A megadott jelszó helytelen.',
                unknown_error: 'Az adatok módosítása sikertelen. Próbálkozz később!'
              }
            },
            fail: {
              name_empty: "Felhasználónév hiányzik.",
              name_too_long: "Felhasználónév max. {{maxLength}} karakter hosszú lehet.",
              phone_invalid: "A telefonszám helytelen.",
              name_exists: "A megadott névvel már létezik felhasználó.",
              not_allowed: 'A megadott jelszó helytelen.',
              unknown_error: "A beállítások mentése sikertelen. Próbálkozz később!"
            }
          }
        },
        select_mon_sys: 'Válassz rendszert!',
        required_field_title: 'Töltsd ki ezt a mezőt!',
      }
    },
    en: {
      translation: {
        device: {
          serial: 'Serial',
          status: {
            title: 'Status',
            unknown: 'Not responding',
            active: 'Active',
            off: 'Caught and turned off',
            undefined: 'Unknown'
          },
          battery: 'Battery [%]',
          temperature: 'Temperature [°C]',
          signal_strength: 'Signal [%]',
          latitude: 'Latitude [°]',
          longitude: 'Longitude [°]',
          location: 'Location',
          googleMaps: 'Show in Google Maps',
          last_seen: 'Last status update',
          disabled_tooltip: 'No device status known yet',
          ignore: 'Start ignoring device. If ignored, I don\'t receive notifications on the device status and it is not shown on my map. Other users are not affected by this.',
          unignore: 'Stop ignoring device. I get notifications on the device status and it is shown on my map. Other users are not affected by this.',
          ignored_title: 'I ignore this device. I don\'t receive notifications on the device status and it is not shown on my map.',
          ignored: 'Ignored',
          not_ignored: 'Not ignored',
        },
        default_ms_name: '{{userName}}\'s system',
        own_ms_name: 'My system',
        default_device_name: '{{id}} device',
        monitoring_system_page:
        {
          disabled_tooltip: 'Not logged in',
          devices_title: 'Devices',
          recent_devices_title: 'Devices active in the last 3 days',
          old_devices_title: 'Other devices',
          topup: {
            budget: 'Monitoring days',
            input: 'Topup code',
            btn: 'Topup',
            btn_tooltip: 'Enter a topup code!',
            no_budget_tooltip: 'No monitoring days left! The system does not function until topup.',
            warn_budget_tooltip: 'Monitoring days are about to be used up (~{{daysLeft}} days left)! Topup now to avoid disruption of the service!',
            webshop_link: 'Click here to purchase a topup code!',
            fail: {
              bad_code: 'Topup code invalid.',
              used_code: 'Topup code already used.',
              unknown_reason: 'Submission of topup code failed. Please try again later!',
            }
          },
          add_device: {
            title: 'Add device',
            input_help: 'Adding code',
            btn: 'Add',
            btn_tooltip: 'Type in the adding code from the ribbon of the device!',
            webshop_link: 'Click here to purchase new devices!',
            fail: {
              bad_code: 'Adding code invalid.',
              used_code: 'Adding code already used.',
              unknown_reason: 'Submission of adding code failed. Please try again later!',
            }
          },
          send_device: {
            title: 'Send device',
            input: 'Email of recipient',
            btn: 'Send',
            btn_tooltip: 'Type in the recipient\'s email address!',
            invalid_email: 'Invalid email address!',
            fail: {
              not_exist: 'No registered user with the entered email address.',
              unknown_reason: 'Sending failed. Please try again later!',
            }
          },
          accept_device: {
            btn: 'Accept {{deviceId}}',
            fail: {
              unknown_reason: 'Accepting device failed. Please try again later!',
            }
          },
          cancel_send_device: {
            btn: 'Cancel sending {{deviceId}}',
            fail: {
              unknown_reason: 'Cancelling sending failed. Please try again later!',
            }
          },
          guests: {
            title: 'Guests',
            input: 'New guest\'s email address',
            btn: 'Invite',
            tooltip: 'Guests see the all the devices and their status in the owner\'s monitoring system and receive status notifications.',
            btn_tooltip: 'Enter the guest\'s email address!',
            invalid_email: 'Invalid email address!',
            delete_btn_tooltip: 'Remove guest',
            no_guest: 'There are no guests yet.',
            leave: 'Leave system',
            fail: {
              not_exist: 'No registered user with the entered email address.',
              already_added: 'A guest is already invited with the entered email address.',
              unknown_reason: 'Invitation failed. Please try again later!',
              remove: 'Removing guest failed. Please try again later!',
              leave: 'Leaving the system failed. Please try again later!',
            }
          }
        },
        map: {
          disabled_tooltip: 'No location data known yet',
          only_show_recent_text: 'Devices active in the last 3 days only',
          only_show_recent_tooltip: 'Only the devices that were active in the last 3 days are shown on the map, the others are hidden'
        },
        user: {
          login: {
            title: 'Login',
            login_btn: 'Login',
            to_signup_btn: 'To registration ->',
            password: 'Password',
            fail: {
              email_empty: 'Email address missing.',
              email_invalid: 'Email address invalid.',
              password_empty: 'Password missing.',
              password_invalid: 'Password must be at least 6 characters.',
              bad_credential: 'Login failed. Check the credentials!',
              unverified: 'Login failed. Registration waiting for email verification.',
              general_issue: 'Login failed. Please try again later!',
            }
          },
          signup: {
            title: 'Registration',
            to_login_btn: '<- To login',
            signup_btn: 'Register',
            password_again: 'Password again',
            agree_static: 'I accept the ',
            agree_dynamic: 'GDPR policy',
            email_sent: 'Registration in progress!<1/>Verify your registration by following the instructions received via email!',
            fail: {
              password_again_not_match: 'Passwords do not match.',
              already_exists: 'There is a registered user with the entered email address.',
              not_agreed: 'Must accept GDPR policy.',
              general_issue: 'Registration failed. Please try again later!',
            }
          },
          pwrequest: {
            btn: 'Forgot password',
            done: 'Password sent to email address.',
            done_tooltip: 'Email with the password was sent to the entered email address.',
            fail: {
              unknown_email: 'No registered use with the entered email address.',
              general_issue: 'Password request failed. Please try again later!'
            }
          },
          verify: {
            btn: 'To login',
            success: 'Successful registration!',
            fail: {
              general_error: 'Verification of registration failed. Please try again later.',
              not_valid: 'Verification of registration not possible, registration process should be restarted.',
            }
          },
          settings: {
            title: 'User settings',
            user_name: 'User name',
            email: 'Email',
            pw: 'New password',
            pw_again: 'New password again',
            phone: 'Phone number',
            phone_not_available: 'Phone number must be valid to select this option',
            notif: {
              event_based: 'Daily report',
              event_based_title: 'Once every day a message is sent with the status of every device that has been active in the last 3 days.',
              is_email: 'Email notification',
              is_phone: 'SMS notification',
              language: {
                title: 'Notification language',
                hu: 'Hungarian',
                en: 'English'
              },
              topup: {
                budget: 'SMS count',
                no_sms_balance_tooltip: 'No more SMS messages are available. Notifications will be sent via email until topped up.',
                warn_sms_balance_tooltip: 'SMS balance is about to be used up (~{{daysLeft}} days left)! Topup now to avoid disruption of the service!',
                input: 'Topup code',
                btn: 'Topup',
                btn_tooltip: 'Enter a topup code!',
                fail: {
                  bad_code: 'Topup code invalid.',
                  used_code: 'Topup code already used.',
                  unknown_reason: 'Topup failed. Please try again later!',
                }
              }
            },
            save_btn: 'Save',
            save_disabled_tooltip_no_change: 'Nothing to be saved.',
            save_disabled_tooltip_error: 'Cannot save with errors.',
            logout_btn: 'Logout',
            update_success: 'Save successful.',
            delete: {
              btn: 'Delete user',
              confirm_title: 'Delete user confirmation',
              confirm_body: 'Are you sure you want to delete your user? Deleting makes all the settings disappear, your own monitoring system gets deleted with all the remaining monitoring days. Please enter your password for confirmation:',
              pw: 'Password',
              confirm_ack_btn: 'Delete',
              confirm_cancel_btn: 'Back',
              fail: {
                not_allowed: 'Password incorrect.',
                has_devices: 'User cannot be deleted as long as the owned monitoring system has devices assigned.',
                negative_budget: 'User cannot be deleted as long as the monitoring system budget is negative.',
                unknown_error: 'Deletion failed. Please try again later!',
              },
            },
            credentials: {
              btn: 'Change login credentials',
              confirm_title: 'Confirmation of login credentials change',
              confirm_body: 'To change the login credentials (email and/or password) enter your password:',
              pw: 'Password',
              confirm_ack_btn: 'Save',
              confirm_cancel_btn: 'Back',
              fail: {
                not_allowed: 'Password incorrect.',
                unknown_error: 'Changing credentials failed. Please try again later!'
              }
            },
            fail: {
              name_empty: "User name missing.",
              name_too_long: "User name can be at most {{maxLength}} characters long.",
              phone_invalid: "Phone number invalid.",
              name_exists: "User name already exists.",
              not_allowed: 'Password incorrect.',
              unknown_error: "Save failed. Please try again later!"
            }
          }
        },
        select_mon_sys: 'Select system!',
        required_field_title: 'Fill out this field!',
      }
    }
  }
})
